import axios from "axios";

import { API_BASE_URL } from "./externalRoutes";

const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    return Promise.reject(error?.response?.data);
  },
);

export const apiGet = (path) => {
  return axiosInstance.get(path);
};

export const apiPost = (path, body, headers) => {
  return axiosInstance.post(path, body, headers);
};
export const apiDelete = (path, config) => {
  return axiosInstance.delete(path, config);
};
