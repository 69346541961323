import React from "react";
import "./Input.styles.css";

export const Input = ({
  param,
  type,
  label,
  error,
  register,
  rules,
}) => {
  return (
    <div className='input-container'>
      <label className='label'>{label}</label>
      <input className='input' type={type} {...register(param, rules)} />
      <p className='errorMessage'>{error}</p>
    </div>
  );
};
