import {  useMutation, useQuery } from 'react-query';
import {
  getUserInfo,
  postChangePassword
} from './auth-service';


export const useUserInfo = (token) => {
  
  const {
    isLoading: isUserInfoLoading,
    data: userInfo,
    error: errorUserInfo,
    isError: isErrorUserInfo,
  } = useQuery(
    ['userInfo'],
    ()=>getUserInfo(token),
  );

  
  return {
    isUserInfoLoading,
    userInfo,
    errorUserInfo,
    isErrorUserInfo,
  };
};


export const useChangePassword = () => {
  const {
    mutate: mutatePassword,
    mutateAsync: mutateAsyncPassword,
    isLoading: isPasswordLoading,
    data: passwordResponse,
    error: errorPassword,
    isError: isErrorPassword,
  } = useMutation(
    async(data) => {
      const response = await postChangePassword(data);
      return response
    },
  );

  return {
    mutatePassword,
    mutateAsyncPassword,
    isPasswordLoading,
    passwordResponse,
    errorPassword,
    isErrorPassword,
  };
};


