import { useChangePassword } from "../../services/auth-service/auth.hooks";
import { Input } from "../Input/Input";
import "./PasswordForm.styles.css";
import { useForm } from "react-hook-form";

export const PassWordForm = ({ user,changeAfterPasswordChagned }) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const {
    mutatePassword,
    mutateAsyncPassword,
    isPasswordLoading,
    passwordResponse,
    errorPassword,
    isErrorPassword,
  } = useChangePassword();

  const INPUTS = [
    {
      label: "Nueva Contraseña",
      type: "password",
      param: "password",
      rules: {
        required: "Tienes que escribir una contraseña",
      },
    },
    {
      label: "Repite Contraseña",
      type: "password",
      param: "confirmedPassword",
      rules: {
        required: "Tienes que escribir una contraseña",
        validate: (val) => {
          if (watch("password") !== val) {
            return "Las contraseñas no coinciden";
          }
        },
      },
    },
  ];

  const onSubmit = async (data) => {
    
    const response = await mutatePassword({...user, ...data})
    changeAfterPasswordChagned()
  
  };

  return (
    <div className="password-container ">
      <h3>Crea una nueva contraseña para {user.user}</h3>
      <form onSubmit={handleSubmit(onSubmit)}>
        {INPUTS.map(({ param, label, type, rules }) => (
          <Input
            param={param}
            key={param}
            type={type}
            label={label}
            register={register}
            error={errors[param]?.message}
            rules={rules}
          />
        ))}
        <button className="submit" type="submit">
          Enviar
        </button>
      </form>
    </div>
  );
};
