import { apiGet, apiPost } from '../apiClient';
import { AUTH_PATHS } from '../externalRoutes';



export const getUserInfo = async (token) => {
  const response = await apiGet(`${AUTH_PATHS.MAIN}/user-recovered/${token}`);
  return response.data.data.user;
};

export const postChangePassword = async (data) => {
  const response = await apiPost(`${AUTH_PATHS.MAIN}/password-reset`, data);
  return response.data.data;
};


