import { useSearchParams } from 'react-router-dom';
import './App.css';
import { PassWordForm } from './components/PasswordForm/PasswordForm';
import { useUserInfo } from './services/auth-service/auth.hooks';
import { useState } from 'react';

function App() {
  const [searchParams, ] = useSearchParams();

// eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjp7ImlkVXNlciI6NCwicm9sZSI6IlJPTEVfU1RVREVOVCJ9LCJpYXQiOjE2ODU1NzQxMzd9.dvOz6lW297Sratttt9MBtmvo2myFTZHekmpHhplLAWQ
  const { isUserInfoLoading, userInfo, errorUserInfo, isErrorUserInfo} = useUserInfo(searchParams.get("token"))
  
  const [isPasswordChanged, setIsPasswordChanged] = useState(false)

  const changeAfterPasswordChagned = ()=>{
    setIsPasswordChanged(true)
  }
    
  if(isUserInfoLoading){
    return <></>
  }

  if(errorUserInfo || isErrorUserInfo){
    return <div>Ha ocurrido un error recuperando la información</div>
  }

  if(isPasswordChanged){

    return (
      <div className="main">
        <div style={{backgroundColor: 'white', padding: '30px', borderRadius: '10px'}}>

       <p>Tu contraseña ha sido cambiada correctamente, vuelve a la aplicación y logueate de nuevo.</p>
        </div>
      </div>
    );
  }

  return (
    <div className="main">
      {userInfo && <PassWordForm user={userInfo} changeAfterPasswordChagned={changeAfterPasswordChagned}/>}
    </div>
  );
}

export default App;
