// export const API_BASE_URL = 'http://localhost:8001/api';
export const API_BASE_URL = 'https://server2.autius.com/api';


const PATHS = {
  AUTH: 'authentication',
};

export const AUTH_PATHS = {
  MAIN: `${PATHS.AUTH}`,
};

